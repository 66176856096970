<template>

  <div class="sport_dropdowns row no-margin" >

    <div class="col-auto text-left highlight-title-text">
      Highlights
    </div>

    <!-- Top Leagues dropdown -->
    <div class="btn-group col-auto text-right">

        <span
            class="dropdown-toggle dropdown-filter"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            v-text="competition_name">
        </span>

        <div class="dropdown-menu">

          <span class="dropdown-item sport_dropdown_item black-txt" @click="setLeague(-1)">All</span>

          <span
              v-for="s in leagues"
              v-bind:key="getKey(s.competition_id)"
              class="dropdown-item sport_dropdown_item black-txt"
              @click="setLeague(s)"
              v-text="s.competition">
          </span>
        </div>

      </div>

    <!-- Time dropdown -->
    <div class="btn-group col-auto">
      <span
          class="dropdown-toggle dropdown-filter"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          v-text="humanHourName(selected_hour)">
      </span>

      <div class="dropdown-menu">
          <span
              v-for="s in hours"
              v-bind:key="getKey(s)"
              class="dropdown-item sport_dropdown_item black-txt"
              @click="setHour(s)"
              v-text="humanHourName(s)">
          </span>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'Dropdown',
  mounted() {

    this.getMarket(1);
    this.setLeague(-1);

  },
  computed: {

    sports: function() {
      return this.$store.state.sports
    },

    leagues: function() {

      if (this.$store.state.leagues !== undefined && this.$store.state.leagues !== null) {

        return this.$store.state.leagues.slice(0, 10);

      }

      return this.$store.state.leagues;

    },

    markets: function() {
      return this.$store.state.markets
    }

  },
  methods: {
    getKey: function(index) {

      return Math.random().toString(20).replace('0.', 'random-id-'+index + '-');

    },
    setSport: function(sport){

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID",sport.sport_id);
      this.$store.dispatch("setSportName",sport.sport_name);
      this.$store.dispatch("setMarketID",0);
      this.$store.dispatch("setCompetitionID",0);

    },

    setLeague: function(league){

      if(league === -1 ) {

        this.competition_name = "Leagues";
        this.$store.dispatch("setCompetitionID",0);
        return
      }

      this.competition_name = league.country_name+' - '+league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.$store.dispatch("setCompetitionID",league.competition_id);
    },
    getMarket: function(sport_id) {
      this.$store.dispatch("getMarkets",sport_id);
    },
    setMarket: function(market){

      this.market = market;
      this.market_name = market.market_name;
      this.$store.dispatch("setOutcomes",market.outcomes);
      this.$store.dispatch("setMarketID",market.market_id);
    },
    setHour: function(h){

      this.selected_hour = h;
      //this.EventBus.$emit('filter:hour',h);
      this.$store.dispatch("setHour",h);

    },
    humanHourName: function(h) {

      if(h === -1 ) {

        return 'All Time'
      }

      return h === '' || h === "" || h === 0 ? 'Time' : h + ' Hours'

    }

  },
  data: function () {
      return {
        sport: {},
        league: {},
        market: {},
        competition_name: 'Top League',
        sport_name: 'Sports',
        market_name: 'Market',
        selected_hour: 0,
        hours: [-1,1,3,6,12,24,48]
      }
  },

}

</script>